import Contex_page from "./contex";
import Modelist_side from "./model_side";
import User_center from "./user";

function Right_side(props) {
    const history = props.history;
    const cu_message_page = props.cu_message_page;
    const scroll_box = props.scroll_box;
    const input_box = props.input_box;
    const up_img = props.up_img;

    //绘图页面
    function Message_side() {
        return (
            <div className="message_side" >
                <Contex_page history={history} scroll_box={scroll_box} input_box={input_box} up_img={up_img} cu_message_page={cu_message_page} />
            </div>
        )
    }
    //个人中心页面
    function Usercenter_side() {
        return (
            <div className="message_side">
                <User_center cu_message_page={cu_message_page} />
            </div>
        )
    }
    function Iframe_panel({src}) {
        return (
            <>
                <iframe src={src} className="gpt_panel" />
            </>
        )
    }

    let output_side;
    switch (cu_message_page) {
        case '创作大厅':
        case '交流':
        case '常见问题':
        case '公告': output_side = <Message_side />; break;
        case '个人资料':
        case '我的积分':
        case '实名认证':
        case '安全中心': output_side = <Usercenter_side />; break;
        case 'DeepSeek': output_side = <Iframe_panel src={"http://localhost:3000/"} />; break;  
        case 'PPT助手': output_side = <Iframe_panel src={"https://kimi.moonshot.cn/kimiplus/conpg18t7lagbbsfqksg"} />; break;    
        case '数字人视频': output_side = <Iframe_panel src={"http://localhost:7860"} />; break;    
        case '翻译通': output_side = <Iframe_panel src={"https://kimi.moonshot.cn/kimiplus/conph6gt7lagfi80vfrg"} />; break;       
        case 'IT百事通': output_side = <Iframe_panel src={"https://kimi.moonshot.cn/kimiplus/conpg4gt7lagbbsfql30"} />; break;       
        case '长文生成器': output_side = <Iframe_panel src={"https://kimi.moonshot.cn/kimiplus/conpga8t7lagcavlq31g"} />; break;       
        case '塔罗师': output_side = <Iframe_panel src={"https://kimi.moonshot.cn/kimiplus/conpgbgt7lagcavlq340"} />; break;       
        case '小红书爆款生成': output_side = <Iframe_panel src={"https://kimi.moonshot.cn/kimiplus/conpdi8t7lag4rq67pqg"} />; break;       
        case '爆款网文生成': output_side = <Iframe_panel src={"https://kimi.moonshot.cn/kimiplus/conpdjgt7lag4rq67pt0"} />; break;       
        default: return;
    }
    return (
        <div className="right_side">
            {output_side}
            {/* {cu_message_page=='创作大厅'?<Modelist_side input_box={input_box} />:''} */}
        </div>
    )
}


export default Right_side;
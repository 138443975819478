import { useEffect, useState } from "react";

import ai_icon from './res/ai.png';

import deepseek from './res/deepseek.png';
import ppt from './res/ppt.png';
import translate from './res/translate.png';
import it from './res/it.png';
import long from './res/long.png';
import taro from './res/taro.png';
import redbook from './res/redbook.png';
import novel from './res/novel.png';
import dog from './res/dog.png';



import shop_icon from './res/shop.png';
import nft_icon from './res/nft.png';
import user_icon from './res/user.png';




function Left_side(props) {
    const history = props.history;
    const set_main_menu_index = props.set_main_menu_index;
    const main_menu_index = props.main_menu_index;
    const set_cu_sec_mem_index = props.set_cu_sec_mem_index;
    const cu_sec_mem_index = props.cu_sec_mem_index;
    const set_cu_message_page = props.set_cu_message_page;

    function Main_menu() {
        function Mem_btn(props) {
            const [cu_class, set_cu_class] = useState("mem_btn");
            return (
                <div onMouseEnter={() => set_cu_class("mem_btn mem_btn_in")} onMouseLeave={() => set_cu_class("mem_btn mem_btn_out")} data-self_index={props.self_index} style={{ backgroundImage: `url(${props.icon})` }} className={cu_class}>
                    <div className="btn_tip">{props.title}</div>
                    {props.self_index == props.main_menu_index ? <div className="mem_seld"></div> : ''}
                </div>
            )
        }
        function do_main_menu(index) {
            if (index == '' || index == null) return;
            if (index == "首页") {
                localStorage.setItem("cu_page", "login");
                window.location.reload();
                return;
            }
            //点击主菜单切换默认页
            set_main_menu_index(index);
            if (index == "AI绘图") {
                set_cu_sec_mem_index("创作大厅");
                set_cu_message_page("创作大厅");
            }
            else if (index == '个人中心') {
                set_cu_sec_mem_index("个人资料");
                set_cu_message_page("个人资料");
            }
            else {
                set_cu_sec_mem_index(index);
                set_cu_message_page(index);
            }

        }
        return (
            <div onClick={(e) => do_main_menu(e.target.dataset.self_index)} className="main_menu">
                <Mem_btn self_index="首页" main_menu_index={main_menu_index} title="首页" icon={shop_icon} />
                <Mem_btn self_index="DeepSeek" main_menu_index={main_menu_index} title="DeepSeek" icon={deepseek} />
                <Mem_btn self_index="AI绘图" main_menu_index={main_menu_index} title="AI绘图" icon={ai_icon} />
                <Mem_btn self_index="PPT助手" main_menu_index={main_menu_index} title="PPT助手" icon={ppt} />
                <Mem_btn self_index="数字人视频" main_menu_index={main_menu_index} title="数字人视频" icon={dog} />
                <Mem_btn self_index="翻译通" main_menu_index={main_menu_index} title="翻译通" icon={translate} />
                <Mem_btn self_index="IT百事通" main_menu_index={main_menu_index} title="IT百事通" icon={it} />
                <Mem_btn self_index="长文生成器" main_menu_index={main_menu_index} title="长文生成器" icon={long} />
                <Mem_btn self_index="塔罗师" main_menu_index={main_menu_index} title="塔罗师" icon={taro} />
                <Mem_btn self_index="小红书爆款生成" main_menu_index={main_menu_index} title="小红书爆款生成" icon={redbook} />
                <Mem_btn self_index="爆款网文生成" main_menu_index={main_menu_index} title="爆款网文生成" icon={novel} />
                <Mem_btn self_index="个人中心" main_menu_index={main_menu_index} title="个人中心" icon={user_icon} />
            </div>
        )
    }
    function Sec_menu() {

        function Sec_menu_list(props) {
            return (
                <div data-self_index={props.self_index} className={cu_sec_mem_index == props.self_index ? "sec_menu_list sec_menu_list_seled" : "sec_menu_list"}>
                    {props.title}
                </div>
            )
        }
        function Sec_menu_list_head() {
            return (
                <div className="sec_memu_list_head">
                    <div className="sec_list_head_title">进画 <span className="user_mobile">{localStorage.getItem("mobile")}</span></div>
                </div>
            )
        }

        function Sec_menu_sd() {
            return (<>
                <Sec_menu_list self_index="创作大厅" title="● 创作大厅" />
                <Sec_menu_list self_index="交流" title="● 交流" />
                <Sec_menu_list self_index="常见问题" title="● 常见问题" />
                <Sec_menu_list self_index="公告" title="● 公告" />
            </>)
        }
        function Sec_menu_user() {
            return (<>
                <Sec_menu_list self_index="个人资料" title="● 个人资料" />
                <Sec_menu_list self_index="我的积分" title="● 我的积分" />
                <Sec_menu_list self_index="我的作品" title="● 我的作品" />
                {/* <Sec_menu_list self_index="实名认证" title="● 实名认证" /> */}
                {/* <Sec_menu_list self_index="安全中心" title="● 安全中心" /> */}
            </>)
        }
        function Sec_show_menu({ title }) {
            return (
                <Sec_menu_list self_index={title} title={"● " + title} />
            )
        }


        function Sec_menu_handclick(e) {

            if (e.target.dataset.self_index == '我的作品') {
                localStorage.setItem("home_user_id", localStorage.getItem("uid"));
                history.push('/user_home');
                return;

            }
            set_cu_message_page(e.target.dataset.self_index);
            set_cu_sec_mem_index(e.target.dataset.self_index);

        }

        let Show_sec;
        if (main_menu_index == "AI绘图")
            Show_sec = <Sec_menu_sd />;

        else if (main_menu_index == "个人中心")
            Show_sec = <Sec_menu_user />;
        else
            Show_sec = <Sec_show_menu title={main_menu_index} />



        return (
            <div className="sec_menu">
                <Sec_menu_list_head />
                <div onClick={(e) => Sec_menu_handclick(e)}>
                    {Show_sec}
                </div>
            </div>
        )
    }

    return (
        <div className='left_side'>
            <Main_menu />
            <Sec_menu />
        </div>
    )
}

export default Left_side